import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 225.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
<path d="M868 1845 c-126 -32 -246 -119 -323 -235 -66 -99 -88 -171 -89 -295
-2 -162 31 -247 154 -398 26 -32 133 -103 190 -126 30 -12 88 -27 128 -33 l72
-12 0 -85 c0 -99 17 -147 71 -200 39 -39 102 -70 142 -71 l27 0 0 209 c0 240
-9 220 105 221 139 2 249 88 263 206 l5 45 -184 -2 -184 -2 -3 92 -3 91 281 0
c225 0 280 3 280 13 0 46 -35 123 -74 161 -71 72 -96 76 -433 76 l-293 0 0
-251 0 -252 -45 6 c-138 19 -243 137 -253 283 -6 97 15 158 79 228 94 103 224
129 362 73 72 -29 153 -36 203 -17 15 6 44 24 65 41 l39 31 -20 28 c-33 47
-150 128 -225 156 -87 33 -246 42 -337 19z"/>
</g>
</svg>

    </Box> 
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
